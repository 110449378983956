/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function preventScrolling() {
          $('.navbar-collapse').on('scroll touchmove mousewheel', function(e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          });
        }

        function offsetHeader() {
          var height = $('#mainHeader').outerHeight();
          $('.banner .navbar-collapse').css('top', height + "px");
        }

        $("[data-trigger]").on("click", function(){

          offsetHeader();
          preventScrolling(); 

          var triggerId =  $(this).attr('data-trigger');
          var hamburger = $('.hamburger');
          $(triggerId).toggleClass('show');
          $('.hamburger').toggleClass('is-active');
          $('body').toggleClass('offcanvas-active');
        });

        // close button 
        $("#primaryNavigation .btn-close").click(function(e){
          $('.navbar-collapse').removeClass('show');
          $('.hamburger').toggleClass('is-active');
          $('body').removeClass("offcanvas-active");
        }); 

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'image_slider': {
      init: function() {
        $('.background-cover--slick').slick({
          autoplay: true,
          autoplaySpeed: 6000,
          slidesToShow: 1,
          fade: true,
          arrows: true
        });
      }
    },
    'client_slider': {
      init: function() {
        $('.clients--slick').slick({
          autoplay: true,
          autoplaySpeed: 6000,
          slidesToShow: 3,
          slidesToScroll: 3,
          mobileFirst: true,
          prevArrow: '.slick--prev',
          nextArrow: '.slick--next',
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            }
          ]
        });
      }
    },
    'testimonials_slider': {
      init: function() {
        $('.testimonials--slick').slick({
          autoplay: true,
          autoplaySpeed: 6000,
          slidesToShow: 1,
          arrows: true,
          responsive: [{
            breakpoint: 992,
            arrows: false
          }]
        });
      }
    },
    'single_project': {
      init: function() {
        $('.owl').owlCarousel({
          loop: false,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          margin: 15,
          nav: true,
          navText : ["",""],
          dots: true,
          center: true,
          items: 1,
          autoHeight: true,
          autoWidth: false,
          URLhashListener: true,
          startPosition: 'URLHash',
          autoplay: false,
          autoplayTimeout: 6000,
          autoplayHoverPause: false
        });

        $('.owl').on('changed.owl.carousel', function(event) {
          var currentItem = event.item.index;
          // Remove .active
          $('.owl-nav li').removeClass('active');
          $('.owl-nav li:nth-child(' + (currentItem+1) + ')').addClass('active');
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'map': {
      init: function() {
        // JavaScript to be fired on the about us page
        mapboxgl.accessToken = 'pk.eyJ1IjoicGl4aXRlIiwiYSI6ImNrZDcwMzBkODF0aTkycW8zN3Eya3RqaHAifQ.zBuMXsPgv-SJZxauPyyaMg';
        
        var map = new mapboxgl.Map({
          container: 'map', // container id
          style: 'mapbox://styles/mapbox/outdoors-v11', // stylesheet location
          center: [146.3717, -31.67284], // starting position [lng, lat]
          zoom: 4 // starting zoom
        });

        geojson.features.forEach(function(marker) {
          // create a DOM element for the marker
          var el = document.createElement('div');
          el.className = 'marker';
          el.style.backgroundImage = 'url(/wp-content/themes/robarcivil/dist/images/icon.png)';

          el.style.width = '60px';
          el.style.height = '60px';

          // add marker to map
          new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML('<h4 class="text-primary">' + marker.properties.name + '</h4><p class="mb-0">' + marker.properties.street + '<br>' + marker.properties.city + '</p>')).addTo(map);
        });

        map.scrollZoom.disable();

        map.on('load', function () {
          map.resize();
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
